<template>
  <div>
    <div class="h3 mb-5 text-center">Arbitrage opportunities</div>
    <b-card class="card-large">
      <div class="card-content">
        <b-row>
          <b-col md="5" class="filters">
            <div class="caption">
              Filter by asset
            </div>
            <b-form-row>
              <b-col>
                <b-input placeholder="Token’s name" v-model="searchToken"></b-input>
              </b-col>
              <b-col class="flex-grow-0">
                <button class="button-flat" v-on:click="filter()">Search</button>
              </b-col>
            </b-form-row>
            <div class="caption mt-3">
              Total amount of pools: {{ totalAmount }}
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <template v-if="!isMobile">
              <b-table borderless
                       :fields="existingPoolsFields"
                       :items="getPoolsList"
                       tbody-tr-class="hover-link">
                <template v-slot:cell(tokenPools)="{item}">
                  <IconCrypto :coinname="item.pair[0].toLowerCase()" color="color" format="svg" class="mr-1"
                              width="18"/>
                  <IconCrypto :coinname="item.pair[1].toLowerCase()" color="color" format="svg" class="mr-1"
                              width="18"/>
                  {{ item.pair[0].toUpperCase() }}/{{ item.pair[1].toUpperCase() }}
                </template>
                <template v-slot:cell(exchangeRate)="{item}">
                  <div>
                    <span v-b-tooltip.hover
                          v-bind:title="bNValue(item.exchange_rate)"
                          v-b-tooltip="{customClass: 'dark-tooltip'}">{{ getExchangeRate(item) }}</span>
                  </div>
                  <div class="caption mt-2">{{ getExchangeRateText(item) }}</div>
                </template>
                <template v-slot:cell(amountOfDeals)="{item}">
                  <div>
                    <template v-if="item.amount_symbol === '$'">$</template>
                    <span v-b-tooltip.hover
                          v-bind:title="bNValue(item.deals_amount)"
                          v-b-tooltip="{customClass: 'dark-tooltip'}">{{ getDealsAmount(item).toLocaleString() }}</span>
                    <template v-if="item.amount_symbol !== '$'"> {{ item.amount_symbol }}</template>
                  </div>
                  <div class="caption mt-2">{{ getDealsAmountText() }}</div>
                </template>
                <template v-slot:cell(poolPressure)="{item}">
                  <div>
                    <span v-b-tooltip.hover
                          v-bind:title="bNValue(item.pressure)"
                          v-b-tooltip="{customClass: 'dark-tooltip'}">{{ getPoolPressureAmount(item.pressure) }}</span>%
                    per min
                  </div>
                  <div class="caption increases mt-2" v-if="item.pressure > 0">increases</div>
                  <div class="caption decreases mt-2" v-else-if="item.pressure < 0">decreases</div>
                  <div class="caption mt-2" v-else>&nbsp;</div>
                </template>
                <template v-slot:cell(action)="{item}">
                  <div class="buttons">
                    <button class="button-stroked button-small" v-on:click="navigateToAddLiquidity(item)">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4Z"
                            fill="#B9F0EE"/>
                        <path
                            d="M6.82843 9.07109L4.58579 9.07109C3.9665 9.07109 3.46447 8.56906 3.46447 7.94977C3.46447 7.33048 3.9665 6.82845 4.58579 6.82845L6.82843 6.82845L6.82843 4.58581C6.82843 3.96652 7.33046 3.46449 7.94975 3.46449C8.56903 3.46449 9.07107 3.96652 9.07107 4.58581L9.07107 6.82845L11.3137 6.82845C11.933 6.82845 12.435 7.33048 12.435 7.94977C12.435 8.56906 11.933 9.07109 11.3137 9.07109L9.07107 9.07109L9.07107 11.3137C9.07107 11.933 8.56903 12.435 7.94975 12.435C7.33046 12.435 6.82843 11.933 6.82843 11.3137L6.82843 9.07109Z"
                            fill="#0BB7AF"/>
                      </svg>
                      Add liquidity
                    </button>
                    <button class="button-flat button-small" v-on:click="navigateToSwap(item)">Trade</button>
                  </div>
                </template>
              </b-table>

            </template>

            <template v-if="isMobile">
              <div class="pool" v-for="(item, index) in getPoolsList" :key="'pool'+index">
                <div class="pool-header">
                  <TokenPair
                      :tFrom=item.pair[0]
                      :tTo=item.pair[1]
                  ></TokenPair>
                  <div>
                    <div>{{ getPoolPressureAmount(item.pressure) }}% per min</div>
                    <div class="caption increases" v-if="item.pressure > 0">increases</div>
                    <div class="caption decreases" v-else-if="item.pressure < 0">decreases</div>
                    <div class="caption" v-else>&nbsp;</div>
                  </div>
                </div>
                <div class="pool-body">
                  <div class="pool-row">
                    <div class="caption">Exchange rate:</div>
                    <div>{{ getExchangeRate(item) }}</div>
                    <div>{{ getExchangeRateText(item) }}</div>
                  </div>
                  <div class="pool-row">
                    <div class="caption">Amount of deals:</div>
                    <div>
                      <template v-if="item.amount_symbol === '$'">$</template>
                      {{ getDealsAmount(item).toLocaleString() }}
                      <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                    <div>{{ getDealsAmountText() }}</div>
                  </div>
                  <div class="buttons">
                    <button class="button-stroked button-small" v-on:click="navigateToAddLiquidity(item)">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4Z"
                            fill="#B9F0EE"/>
                        <path
                            d="M6.82843 9.07109L4.58579 9.07109C3.9665 9.07109 3.46447 8.56906 3.46447 7.94977C3.46447 7.33048 3.9665 6.82845 4.58579 6.82845L6.82843 6.82845L6.82843 4.58581C6.82843 3.96652 7.33046 3.46449 7.94975 3.46449C8.56903 3.46449 9.07107 3.96652 9.07107 4.58581L9.07107 6.82845L11.3137 6.82845C11.933 6.82845 12.435 7.33048 12.435 7.94977C12.435 8.56906 11.933 9.07109 11.3137 9.07109L9.07107 9.07109L9.07107 11.3137C9.07107 11.933 8.56903 12.435 7.94975 12.435C7.33046 12.435 6.82843 11.933 6.82843 11.3137L6.82843 9.07109Z"
                            fill="#0BB7AF"/>
                      </svg>
                      Add liquidity
                    </button>
                    <button class="button-flat button-small" v-on:click="navigateToSwap(item)">Trade</button>
                  </div>
                </div>
              </div>

            </template>
          </b-col>
        </b-row>

      </div>
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
        <span slot="no-more"></span>
        <div slot="no-results"></div>
      </infinite-loading>
    </b-card>
  </div>
</template>

<script>
import {HTTP_GET_POOLS_COUNT, HTTP_GET_POOLS_DATA} from "@/store/modules/pools.module";
import {mapActions} from "vuex";
import BigNumber from "bignumber.js";

export default {
  name: "Arbitrage",
  components: {
    TokenPair: () => import("@/components/form/TokenPair"),
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    }
  },
  data: function () {
    return {
      existingPoolsFields: [
        {key: 'tokenPools', thClass: 'caption'},
        {key: 'exchangeRate', thClass: 'caption'},
        {key: 'amountOfDeals', thClass: 'caption'},
        {key: 'poolPressure', thClass: 'caption'},
        {key: 'action', thClass: 'action'}
      ],
      getPoolsList: [],
      totalAmount: 0,
      page: 1,
      size: 10,
      searchToken: '',
      infiniteId: +new Date()
    }
  },
  watch: {
    '$store.state.web3.networkId': {
      handler: async function (val) {
        if (val) {
          this.filter()
        }
      },
    },
  },
  methods: {
    ...mapActions([HTTP_GET_POOLS_DATA, HTTP_GET_POOLS_COUNT]),
    async infiniteHandler($state) {
      let params = {
        page: this.page - 1,
        size: this.size,
        sort: 'deals_amount',
        token: this.searchToken === '' ? null : this.searchToken.toUpperCase()
      }
      await this.httpGetPoolsCount().then(res => {
        this.totalAmount = res.count
      })
      await this.httpGetPoolsData(params).then(res => {
        if (res.content.length > 0) {
          this.page += 1
          this.getPoolsList.push(...res.content)
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch(() => {
        $state.complete();
      })
    },
    filter() {
      this.page = 1;
      this.getPoolsList = [];
      this.infiniteId += 1;
    },
    navigateToAddLiquidity(pool) {
      this.$router.push(`/liquidity/pool?f=${pool.pair[0]}&t=${pool.pair[1]}`)
    },
    navigateToSwap(pool) {
      this.$router.push(`/swap?f=${pool.pair[0]}&t=${pool.pair[1]}`)
    },
    mathRound(number, decimalPlaces) {
      const factorOfTen = Math.pow(10, decimalPlaces)
      return Math.round(number * factorOfTen) / factorOfTen
    },
    bNValue(value) {
      return new BigNumber(value).valueOf()
    },
    getExchangeRate(pool) {
      return this.mathRound(pool.exchange_rate, 6)
    },
    getExchangeRateText(pool) {
      return `${pool.pair[0]} for 1 ${pool.pair[1]}`
    },
    getDealsAmount(pool) {
      return this.mathRound(pool.deals_amount, 0)
    },
    getDealsAmountText() {
      return 'last 30 days'
    },
    getPoolPressureAmount(poolPressure) {
      return this.mathRound(poolPressure * 100, 2)
    }
  }
}
</script>

<style scoped>

</style>
